<template>
  <div>
    <page-header
      :background="require('@/assets/leagues/img.png')"
      title="Jogos"
    >
      <v-btn
        class="rounded-l-0 rounded-r-lg text-none"
        color="primary"
        to="/account/games"
      >
        <v-icon left>mdi-arrow-right</v-icon> Meus jogos
      </v-btn>
    </page-header>

    <v-container class="py-12">
      <div class="d-flex flex-column">
        <v-slide-group show-arrows="always">
          <v-slide-item v-for="(item, i) in modalities" :key="i" class="py-8">
            <GameCard :game="item" />
          </v-slide-item>
        </v-slide-group>
      </div>
    </v-container>
  </div>
</template>

<script>
import { mapState } from "vuex";
import GameCard from "@/components/games/GameCard.vue";

export default {
  components: {
    GameCard,
  },

  computed: {
    ...mapState(["modalities"]),
  },
};
</script>

<style></style>
