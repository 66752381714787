<template>
  <v-hover v-slot="{ hover }">
    <div>
      <v-card
        class="zoom rounded-lg mx-4"
        color="surface"
        width="250"
        max-width="250"
        min-width="250"
      >
        <v-img :src="game.thumb" :aspect-ratio="4 / 4" class="rounded-t-lg">
          <div
            v-if="hover"
            class="d-flex fill-height align-end justify-center opacity"
          >
            <v-btn
              class="rounded-l-0 rounded-r-lg text-none mb-6"
              color="primary"
              :x-large="$vuetify.breakpoint.mdAndUp"
              :to="`/leagues?gameId=${game.id}`"
            >
              <v-icon left>mdi-arrow-right</v-icon>
              Saiba mais
            </v-btn>
          </div>
        </v-img>

        <div class="background-gradient d-flex flex-column align-center py-2">
          <span class="text-h6 px-2">
            <span class="saira">{{ game.name }}</span>
          </span>

          <v-btn :to="`/leagues?gameId=${game.id}`" text>
            <v-icon left v-text="'mdi-calendar'" />
            <span class="primary--text" v-text="'Confira a agenda'" />
          </v-btn>
        </div>
      </v-card>
    </div>
  </v-hover>
</template>

<script>
export default {
  props: {
    game: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
.background-gradient {
  background: radial-gradient(
    152.6% 32781.24% at 100% 50%,
    #1f2125 0%,
    rgba(31, 33, 37, 0) 100%
  );
}
</style>
